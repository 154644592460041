/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { RichText } from "prismic-reactjs"
import SocialTags from "../utils/social"

import Header from "./header"
import Footer from "./footer"

import "../scss/app.scss"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ doc, headerNav, secondaryNav, footerNav, settings, loc, textColor, children }) => {

  let social = SocialTags(doc, doc.social, (loc ? loc : null))

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>{doc.meta_title ? doc.meta_title : RichText.asText(doc.title)}</title>
        <meta name="description" content={doc.meta_description ? doc.meta_description : ''}></meta>
        {doc._meta ? <html lang={doc._meta.lang} /> : ''}
        {social}
      </Helmet>

      <div className="site-content" style={{ '--text-color': textColor }}>
        <Header nav={headerNav} secondaryNav={secondaryNav} parentPageId={doc.parent?._meta.id} activePage={{...doc._meta, translation_uid: doc.translation_uid}} logo={settings.site_icon} />
        <main style={{ '--bg-color': (doc.bg_color || '#F4F4F0') }}>{children}</main>
        <Footer nav={footerNav} logo={settings.site_logo} meta={settings.site_meta} social={settings.social}></Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
