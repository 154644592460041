import React, { useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'
import Navbar from 'react-bootstrap/Navbar'
import Menu from './menu'
import cn from 'classnames'


export const query = graphql`
fragment HeaderNavFragment on Query {
  header: prismic {
    navigation(uid: "core-navigation", lang: $lang) {
      title
      items {
        dropdown_children {
          ... on PRISMIC_Navigation {
            title
            _linkType
            items {
              icon
              label
              description
              link {
                ... LinkFragment
              }
            }
          }
        }
        label
        icon
        link {
          ... LinkFragment
        }
      }
    }
  }
  secondaryNav: prismic {
    navigation(uid: "secondary-navigation", lang: $lang) {
      title
      items {
        label
        icon
        description
        link {
          ... LinkFragment
        }
      }
    }
  }
}`


export default ({ nav, secondaryNav, parentPageId, activePage, logo }) => {

    const [expanded, setExpanded] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleHandler = (state) => {
        setExpanded(state);
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrolled( window.scrollY > 1 )
        })
    }, [])

    return (
      <header className={cn('site-header', { scrolled: scrolled })}>
          <div className="container">
            <Navbar collapseOnSelect expand="lg" variant="light" expanded={expanded} onToggle={(cur) => setExpanded(cur)}>
                <div className="navbar-left">
                  <Link className="navbar-brand" to="/">
                      {logo ? <img src={logo.url} alt={logo.alt} className="img-fluid" /> : <></>}
                  </Link>

                  <Navbar.Toggle aria-controls="responsive-navbar-nav" className={{ expanded: expanded}}>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </Navbar.Toggle>
                </div>

                <div class="navbar-right ml-lg-auto">
                  <div class="navbar-right ml-lg-auto">
                    {nav ? (
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <Menu toggleHandler={toggleHandler} items={nav.items} activePage={activePage} active={activePage.id} parentPageId={parentPageId} className="primary-nav" />

                        {secondaryNav ? (
                            <Menu toggleHandler={toggleHandler} items={secondaryNav.items} activePage={activePage} className="secondary-nav" />
                          ) : <></>
                        }
                      </Navbar.Collapse>
                    ) : <></>}
                  </div>
                </div>
            </Navbar>
          </div>
      </header>
    )
}
