import React from 'react'

export default ( page, social, loc ) => {

    const facebookGlobalMeta = []

    if ( loc ) { 
      facebookGlobalMeta.push({ key: 'url', value: process.env.GATSBY_SITE_DOMAIN + loc.pathname })
    }

    const facebookMeta = [
        { key: 'title' },
        { key: 'description' },
        { key: 'image', value: (value) => value ? value.url : ''},
        { key: 'type', value: (value) => value || (page._meta && page._meta.og_type) || 'website'}
    ]

    const twitterCardType = ( type ) => {
        const types = {
            'Standard Summary Card': 'summary',
            'Summary Card with Large Image': 'summary_large_image',
        }
        return types[ type ]
    }

    const twitterMeta = [
        { key: 'card_type', property: 'card', value: (value) => twitterCardType(value) },
        { key: 'twitter_handle', property: 'creator' },
        { key: 'title' },
        { key: 'description' },
        { key: 'image', value: (value) => value ? value.url : '' },
    ]


    const tags = []
    
    const globalTags = facebookGlobalMeta.map( tag => {
        let property = tag.property || tag.key
        return <meta property={'og:' + property} content={tag.value}></meta>
    })

    const localTags = social && social.map(slice => {

        if ( slice.type === 'general_card' ) {

          return facebookMeta.map( tag => {
            let property = tag.property || tag.key
            if ( ! slice.primary[tag.key] && ! tag.value ) return null
            return <meta property={'og:' + property} content={tag.value ? tag.value(slice.primary[tag.key]) : slice.primary[tag.key]}></meta>
          })

        } else if ( slice.type === 'twitter_card' ) {

          return twitterMeta.map( tag => {
            let property = tag.property || tag.key
            if ( ! slice.primary[tag.key] && ! tag.value ) return null
            return <meta name={'twitter:' + property} content={tag.value ? tag.value(slice.primary[tag.key]) : slice.primary[tag.key]}></meta>

          })
        }

        return null

    })

    tags.push( globalTags, localTags )

    return tags

}