import React from 'react'
import { graphql } from 'gatsby'
import { getColumnClass } from '../utils/columns'
import Link from '../components/link'


export const query = graphql`
fragment FooterNavFragment on Query {
  footer: prismic {
    navigation(uid: "footer-navigation", lang: $lang) {
          title
          items {
              label
              link {
                ... LinkFragment
              }
            }
            nav {
                ... on PRISMIC_NavigationNavSub_nav {
                    label
                    primary {
                        parent_link {
                            ... LinkFragment
                        }
                    }
                    fields {
                        label
                        link {
                            ... LinkFragment
                        }
                        link_anchor {
                            ... LinkAnchorFragment
                        }
                    }
                }
            }
          }
    }
  }`


export default (props) => {

    let footer = (
        <nav className="footer-nav">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        {props.logo ? <img src={props.logo.url} alt={props.logo.alt} className="site-logo img-fluid" /> : <></>}
                    </div>
                    {props.nav.items.map((item, index) => {
                    
                        let sub = props.nav.nav.find(item2 => item.link._meta.id === item2.primary.parent_link._meta.id )
                        //return <>{JSON.stringify(sub)}</>
                        //if ( ! item.primary.link ) return
            
                        return (
                            <div className={getColumnClass('1/4')} key={index}>
                                <ul>
                                    <h3 className="o-title">{<Link to={item.link} navLink="true">{item.label}</Link>}</h3>
                                    {sub && sub.fields && sub.fields.map((item, index) => {
                                        if (item.label === 'Blog') {
                                            return (
                                                <li key={index}>
                                                    {<Link to={{ absolute_path: true, path: '/blog' }} navLink="true">{item.label}</Link>}
                                                </li>
                                            )
                                        }
                                        if (!item.link) return <li><span>{item.label}</span></li>
                                        return <li key={index}>{<Link to={item.link} anchor={item.link_anchor} navLink="true">{item.label}</Link>}</li>
                                    })}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </div>
        </nav>
    )

    let meta = (
        <div class="site-meta">
            <div className="container">
                <ul class="meta-items">
                    {props.meta.map(item => {
                        return (
                            <li>
                                {item.icon ? <img src={item.icon.url} alt={item.icon.alt} className="img-fluid" /> : <></>}
                                {item.link ? <Link to={item.link}>{item.text}</Link> : <span>{item.text}</span>}
                            </li>
                        )
                    })}
                </ul>
                <ul class="social-items">
                    {props.social.map(item => {
                        return (
                            <li>
                                {item.icon ? <a href={item.link?.url || ''} target="_blank" rel="noreferrer"><img src={item.icon.url} alt={item.icon.alt} className="img-fluid" /></a> : <></>}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )

    return (
        <footer className="site-footer">
            <div class="inner">
                {footer}
            </div>
            {meta}
        </footer>
    )


}