const getColumnClass = (width, offset) => {
    let columns = selectToClassName( width )
    return `col-md-${columns}`
}


const getPureColumnClass = ( normal, width, def, offset ) => {
    let normalSuffix = selectToPureClassName( width );
    let offsetSuffix = selectToPureClassName( offset );
    let offsetClass = 'offset-md-' + offsetSuffix;

    return 'pure-u-' + (normal ? normal : '1')
        + ' pure-u-md-' + ( normalSuffix ? normalSuffix : def )
        + (offsetSuffix ? ' ' + offsetClass : '');
}


const getColumnRemainderClass = (width) => {
    let remainder = getColumnRemainderCount( width )
    return 'col-md-' + remainder
}


const getPureColumnRemainderClass = (width) => {
    const remainderAmount = !width || width === '1/2' ? '12' : getColumnRemainderCount( width ) * 2;
    return 'pure-u-1 pure-u-md-' + remainderAmount + '-24';
}


const columnFractionToCount = (width) => {
    let explode = width.split('/')
    let fraction = 12 / parseInt( explode[1] )
    let ret = parseInt( explode[0] ) * fraction
    return ret
}


const getColumnRemainderCount = (width) => {
    let amount = columnFractionToCount( width )
    let remainder = 12 - amount
    return remainder
}


const selectToClassName = (width) => {
    if ( ! width ) return false;
    return columnFractionToCount( width )
}


const selectToPureClassName = (width) => {
    if ( ! width ) return false;
    if ( width === 'full' ) return '1';

    let className = false;
    let explo = width.split('/');

    // swap out forward slash for a hypen and use it as a class name
    // e.g. 2/3 -> 2-3 orr 3 -> 1/3
    className = explo.length > 1 ? explo[0] + '-' + explo[1] : '1-' + explo[0];

    return className;
}


export { getColumnClass, getPureColumnClass, getColumnRemainderClass, getPureColumnRemainderClass }
