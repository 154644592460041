import React from "react"
import { Link } from "gatsby"
import { Link as PrismicLink } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'

const CustomLink = ({ to, anchor, children, className }) => {

  if (to._linkType === "Link.web") {
    return <a href={to.url} className={className} target="_blank" rel="noreferrer">{children}</a>

  } else if (to.absolute_path) {
    return (<Link to={to.path}>{children}</Link>)
  }


  return (
    <Link 
        to={PrismicLink.url({link_type: 'Document', ...to._meta}, linkResolver) + (anchor && anchor._meta ? `#${anchor._meta.uid}` : '')}
        className={className}>
        {children}
    </Link>
  )

}

export const RichTextLink = (type, element, content, children, index) => (
    <CustomLink key={element.data.id} to={{ _linkType: type, _meta: element.data}}>
        {content}
    </CustomLink>
)


export default CustomLink
