import React from 'react'

import Dropdown from 'react-bootstrap/Dropdown'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavItem from 'react-bootstrap/NavItem'
import NavLink from 'react-bootstrap/NavLink'

import Link from '../components/link'


export default ({ items, activePage, active, parentPageId, className, toggleHandler }) => {
    return (
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={className}>
                {items && items.map((item) => {
                    if (!item.link) return null

                    const icon = item.icon ? <img src={item.icon.url} className="nav-icon" aria-hidden="true" alt=""></img> : <></>;
                    const isTranslationLink = item.description === 'Translation link';

                    const isPageTranslated = activePage.type === 'page';
                    const newPath = '/' + (activePage.lang === 'is' ? 'en-gb/' : '') + activePage.translation_uid;

                    const element = item.dropdown_children ? (
                        <CustomDropdown item={item} toggleHandler={toggleHandler}></CustomDropdown>
                    ) : (
                        (isTranslationLink && !isPageTranslated) ? (
                            <></>
                        ) : (
                            <Link
                                to={isTranslationLink ? { absolute_path: true, path: newPath } : item.link}
                                className={[(active && item.link?._meta?.id === active) || (parentPageId && item.link?._meta.id === parentPageId) ? 'active' : null]}
                            >
                                {item.label}
                                {icon}
                            </Link>
                        )
                    )

                    return element;
                })}
            </Nav>
        </Navbar.Collapse>
    )
}

class CustomDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isOpen: false }
    }

    handleOpen = () => {
        clearTimeout(this.id)
        this.setState({ isOpen: true })
    }

    handleClose = () => {
        clearTimeout(this.id)

        this.id = setTimeout(() => {
            this.setState({ isOpen: false })
        }, 40)
    }

    handleClick = () => {
        this.setState( { isOpen: !this.state.isOpen } )
    }

    render() {
        return (
            <Dropdown as={NavItem} show={this.state.isOpen} onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose}>
                <div className="d-flex align-items-center justify-content-between">
                    <Dropdown className="navLinklabel pr-2" navLink="true" activeClassName="selectedLink" to={this.props.item.link}>
                        {this.props.item.label}
                    </Dropdown>

                    <Dropdown.Toggle className="dropdown-anchor pr-md-4 pl-md-0" as={NavLink} onClick={this.handleClick} navLink="true" />
                </div>

                <Dropdown.Menu>
                    <div className="dialog-box">
                        <ul className="sub-menu">
                            {this.props.item.dropdown_children.items.map(item => {
                                return (
                                    <li>
                                        <Link navLink="true" anchor={item.link_anchor} to={item.link}>
                                            <div className="nav-item-container">
                                                <div className="nav-item-label">{item.label ? item.label : ""}</div>
                                                <div className="nav-item-description">{item.description ? item.description : ""}</div>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}
